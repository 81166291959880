import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

import "../styles/index.css"

// import "tailwindcss/base.css"
// import "tailwindcss/components.css"
// import "tailwindcss/utilities.css"

import Logo from "../images/logo.png"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header data={data} />
      {children}
      <Footer />
    </>
  )
}

function Header(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  return (
    <div className="relative bg-white">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div className="-mt-2">
          <Link to="/" className="flex">
            <img
              className="w-auto h-8"
              src={Logo}
              alt={props?.data?.siteMetadata?.title}
            />
          </Link>
        </div>
        {/* Hamburger menu icon */}
        <div className="-mr-2 -my-2 md:hidden">
          <button
            onClick={() => setShowMobileMenu(true)}
            type="button"
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
          >
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        {/* Desktop menu */}
        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
          <nav className="flex space-x-10">
            <div className="relative">
              <Link
                to="/blog"
                className="group text-lg text-gray-500 inline-flex items-center space-x-2 leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
              >
                Blog
              </Link>
            </div>
          </nav>
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {showMobileMenu && (
          <motion.div
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
          >
            <div className="rounded-lg shadow-lg">
              <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5 space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <Link to="/" className="flex">
                        <img
                          className="w-auto h-8"
                          src={Logo}
                          alt={props?.data?.siteMetadata?.title}
                        />
                      </Link>
                    </div>
                    <div className="-mr-2">
                      <button
                        onClick={() => setShowMobileMenu(false)}
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      >
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div>
                    <nav className="grid gap-6">
                      <Link
                        to="/blog"
                        className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <div className="text-base leading-6 font-medium text-gray-900">
                          Blog
                        </div>
                      </Link>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function Footer() {
  return (
    <div className="bg-white mt-20">
      <div className="max-w-screen-xl mx-auto py-2 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto py-2 px-4 overflow-hidden sm:px-6 lg:px-8">
            <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
              <div className="px-5 py-2">
                <a
                  href="/"
                  className="text-base leading-6 text-gray-500 hover:text-gray-900"
                >
                  Home
                </a>
              </div>
              <div className="px-5 py-2">
                <a
                  href="/blog"
                  className="text-base leading-6 text-gray-500 hover:text-gray-900"
                >
                  Blog
                </a>
              </div>
            </nav>
          </div>
        </div>
        <div className="mt-4 mb-12">
          <p className="text-center text-base leading-6 text-gray-400">
            &copy; 2020 Primespot Services. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
